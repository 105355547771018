import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        
    },
    formControl: {
        width: "100%",
        marginTop: "2rem"
    },
    label: {
        fontSize: "13px",
        fontWeight: "400",
        marginBottom: ".1rem",
      },
      header: {
        borderBottom: "2px solid #D7DCE0",
        paddingBottom: "0.4rem",
      },
    numberInput: {
        '& ::-webkit-outer-spin-button, & ::-webkit-inner-spin-button' : {
            '-webkit-appearance': 'none',
            'margin': 0
        },
        '& ::-webkit-inner-spin-button' : {
            '-webkit-appearance': 'none',
            'margin': 0
        },
        '& [type=number]' : {
            '-moz-appearance': 'textfield'
        },

    },
    optionsContainer: {
        display: "flex",
        width: "100%",
        flexWrap: "wrap",
        justifyContent: "space-between"
    },
    optionItem: {
        width: "48%",
        border: "1px solid #D7DCE0",
        borderRadius: "2px",
        height: "9rem",
        display: "flex",
        justifyContent:"center",
        alignItems: "center",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#E8EFFD",
            border: `1px solid ${theme.palette.primary.main}`
          },
    },
    last: {
        width: "100%",
        border: "1px solid #D7DCE0",
        borderradius: "2px",
        marginTop: "1rem",
        height: "8rem",
        display: "flex",
        justifyContent:"center",
        alignItems: "center",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#E8EFFD",
            border: `1px solid ${theme.palette.primary.main}`
          },
    },
    icon: {
        fontSize: "55px",
        color: " #505D67",
        "&:hover": {
            color: theme.palette.primary.main,
          },
    },

    selected: {
        color: theme.palette.primary.main,
    },
    selectedBox: {
        backgroundColor: "#E8EFFD",
        border: "1px solid #117AF3",
    }
  
}));

export { useStyles }