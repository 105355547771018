import { makeStyles } from "@material-ui/core/styles"
import eligibilityImage from "../../../images/eligibility-background.jpg"

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "6.6rem",
    // height: "100vh",
    paddingBottom: "8rem",
    backgroundColor: "#E8EFFD",
    // backgroundImage: `linear-gradient(0deg, rgba(50, 100, 200, 0.9), rgba(50, 100, 200, 0.9)), url(${eligibilityImage});`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  eligibilityBox: {
    margin: "0rem auto",
    // border: "2px solid green",
    width: "35rem",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  subTitle: {
    padding: "0 2rem",
  },
  whiteText: {
    color: "#ffffff",
  },
  title: {
    marginLeft: "1.6rem",
  },
  backAction: {
    display: "inline-flex",
    alignItems: "center",
    marginBottom: "0.8rem",
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "-3rem",
    }
  },
  icon: {
    border: "1px solid #D7DCE0",
    borderRadius: "4px",
  }
}))

export { useStyles }
