import React, { useContext, useEffect } from "react"
import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import Paper from "../../Paper"

import EligibilityIntro from "./EligibilityIntro"
import EligibilityFeedback from "./EligibilityFeedback"
import EligibilityForm from "./EligibilityForm"

import loanContext from "../../../context/loan/loanContext"

import { useStyles } from "./style"

const EligibilityCheckLayout = () => {
  const classes = useStyles()
  const {
    resetEligibilityFormStep,
    handleEligibilityPreviousStep,
    eligibility: { activeStep },
  } = useContext(loanContext)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  useEffect(() => {
    //to make sure the user is taken back to top of the page
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }, [activeStep])

  useEffect(() => {
    activeStep !== 0 && resetEligibilityFormStep()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const { activeStep } = eligibility
  const getActiveStepContent = () => {
    switch (activeStep) {
      case 0:
        return <EligibilityIntro />

      case 1:
      case 2:
        return <EligibilityForm />

      case 3:
        return <EligibilityFeedback />

      default:
        return <EligibilityIntro />
    }
  }

  return (
    <div className={classes.root}>
      <Box className={classes.eligibilityBox} paddingTop={"3rem"}>
        {activeStep === 0 &&
          (<>
            <Typography
              variant="h2"
              align="center"
              gutterBottom
            >
              Eligibility Check
            </Typography>
            <Typography className={classes.subTitle} align="center">
              Do a quick eligibility check to find out the maximum{!isMobile && <br />} amount
              of loan you can take. It’s short and simple.
            </Typography>
          </>
          )}

        <Box marginTop={"2rem"}>
          {activeStep > 0 && activeStep < 3 && (
            <Box
              className={classes.backAction}
              onClick={handleEligibilityPreviousStep}
            >
              <ArrowBackIcon fontSize="small" className={classes.icon}/>{" "}
              <Typography variant="h4" className={classes.title} style={{ marginLeft: "1.6rem" }}>Eligibility Test</Typography>
            </Box>
          )}
          <Paper padding="1rem 3rem 2rem" borderRadius="2px">
            {getActiveStepContent()}
          </Paper>
        </Box>
      </Box>
    </div>
  )
}

export default EligibilityCheckLayout
