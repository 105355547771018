import React from "react"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import Slider from "@material-ui/core/Slider"
import Typography from "@material-ui/core/Typography"
import PropTypes from "prop-types"

const useStyles = makeStyles(theme => ({
  root: {
    // width: 300 + theme.spacing(3) * 2,
  },
  margin: {
    height: theme.spacing(3),
  },
}))

const CustomSlider = withStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    height: 8,
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: "#fff",
    border: "2px solid #117AF3",
    marginTop: -6,
    marginLeft: -15,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50%)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
}))(Slider)

const InputSlider = ({ min, max, handleSliderChange, value, defaultValue }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography>{min}</Typography>
        <Typography>{max}</Typography>
      </div>
        <CustomSlider
          valueLabelDisplay="auto"
          max={max ? max : 100}
          min={min ? min : 0}
          onChange={handleSliderChange}
          defaultValue={defaultValue ? defaultValue : 0}
        />
        {/* {value && (
          <Typography style={{ marginLeft: "2rem" }}>{value}</Typography>
        )} */}
      {/* </div> */}
    </div>
  )
}

export default InputSlider

InputSlider.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    handleSliderChange: PropTypes.func.isRequired,
    value: PropTypes.number,
    defaultValue: PropTypes.number,
}
