import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        
    },
    introOptions: {
        display: "flex",
        flexDirection: "column",
        // marginTop: "1.5rem"
    },
}));

export { useStyles }