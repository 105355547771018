import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {},
  statusIconContainer: {
    width: "8rem",
    height: "8rem",
    borderRadius: "50%",
    border: status =>
      status ? "2px solid #0F42A4" : "2px solid #CA1B1B",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  statusIcon: {
    width: "6rem",
    height: "6rem",
    borderRadius: "50%",
    backgroundColor: status =>
      status ? "#0F42A4" : "#CA1B1B",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#ffffff",
    fontSize: "4rem",
  },
  iconContainer: {
    width: "125px",
    height: "125px",
    borderRadius: "50%",
    backgroundColor: "#EFF2F6",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "1.5rem"
  },
}))

export { useStyles }
