import React from 'react'
import { Box } from '@material-ui/core'
import SEO from "../components/seo"
import Layout from "../components/Layout/"
import EligibilityCheckLayout from '../components/Layout/EligibilityCheckLayout'

const EligibilityCheck = () => {
    return (
        <>
            <SEO title="Check Eligibility" />
            <Layout withFooter withHeader>
                <Box>
                    <EligibilityCheckLayout />
                </Box>
            </Layout>
        </>
    )
}

export default EligibilityCheck
