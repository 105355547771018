import React, { useContext } from "react"
import { Box, Typography } from "@material-ui/core"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"
import Button from "../../../Button"
import loanContext from "../../../../context/loan/loanContext"

import { useStyles } from "./style"

const EligibilityIntro = () => {
  const classes = useStyles()

  const { handleEligibilityNextStep } = useContext(loanContext)

  const introDetails = [
    "Takes about 2 mins to complete",
    "No need to upload documents",
    "Regardless of your income, there’s a loan for you",
    "We won’t ask you any yeye questions that you will take your time",
  ]
  return (
    <Box marginBottom={"1rem"}>
      <ul className={classes.introOptions}>
        {introDetails.map((detail, i) => (
          <li key={i} style={{ marginTop: "1rem" }}>
            {/* <CheckCircleOutlineIcon color="primary" fontSize="small" /> */}
            <Typography style={{ marginLeft: "1rem" }}>{detail}</Typography>
          </li>
        ))}
      </ul>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        marginTop={"3rem"}
        marginBottom={"1rem"}
        onClick={handleEligibilityNextStep}
      >
        Start
      </Button>
    </Box>
  )
}

export default EligibilityIntro
